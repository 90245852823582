<template>
    <div class="section">
        <div class="modal" style="width: auto" v-bind:class="{ 'is-active': isShowEditDialog }">
            <div class="modal-background"></div>
            <div class="modal-card scrollable" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">Edit Fuel Inventory Close Balance</p>&nbsp;&nbsp;
                    <button class="delete is-danger" type="button" @click="closeDialog">Cancel</button>
                </header>
                <section class="modal-card-body">
                    <div class="columns is-mobile">
                        <div class="column">
                            Date:
                        </div>
                        <div class="column">
                            {{ fuelInvEdit.date | formatDate }}
                        </div>                    
                    </div>
                    <div class="columns is-mobile">
                        <div class="column">
                            Product:
                        </div>
                        <div class="column">
                            {{ fuelInvEdit.product }}
                        </div>                    
                    </div>
                    <div class="columns is-mobile">
                        <div class="column">
                            Open:
                        </div>
                        <div class="column">
                            {{ formatNumberWithCommas(fuelInvEdit.startingVolume,3)}}
                        </div>                    
                    </div>
                    <div class="columns is-mobile">
                        <div class="column">
                            Delivery:
                        </div>
                        <div class="column">
                            {{ formatNumberWithCommas(fuelInvEdit.deliveredVolume,3)}}
                        </div>                    
                    </div>
                    <div class="columns is-mobile">
                        <div class="column">
                            Sales:
                        </div>
                        <div class="column">
                            {{ formatNumberWithCommas(fuelInvEdit.soldVolume,3)}}
                        </div>                    
                    </div>
                    <div class="columns is-mobile">
                        <div class="column">
                            Balance:
                        </div>
                        <div class="column">
                            {{ formatNumberWithCommas(fuelInvEdit.atgBalance,3) }}
                        </div>                    
                    </div>
                    <div class="columns is-mobile">
                        <div class="column">
                            ATG Close:
                        </div>
                        <div class="column">
                            <VeeInput
                                type="number" 
                                v-model="fuelInvEdit.endingVolume"
                                name="endingVolume"
                                :required="fuelInvEdit.endingVolume == 0"
                                :value="fuelInvEdit.endingVolume"
                                :editable="editable"
                                @focus="$event.target.select()"
                                />
                        </div>                    
                    </div>
                    <div class="columns is-mobile">
                        <div class="column">
                            ATG Variance:
                        </div>
                        <div class="column">
                            {{ formatNumberWithCommas((fuelInvEdit.endingVolume - (fuelInvEdit.startingVolume + fuelInvEdit.deliveredVolume - fuelInvEdit.soldVolume)),3)}}
                        </div>                    
                    </div>
                    <div class="columns is-mobile">
                        <div class="column">
                            POS Delivery:
                        </div>
                        <div class="column">
                            <VeeInput
                                type="number" 
                                v-model="fuelInvEdit.deliveredVolumePOS"
                                name="deliveredVolumePOS"
                                :required="fuelInvEdit.deliveredVolumePOS == 0"
                                :value="fuelInvEdit.deliveredVolumePOS"
                                :editable="editable"
                                @focus="$event.target.select()"
                                />
                        </div>                    
                    </div>
                    <div class="columns is-mobile">
                        <div class="column">
                            POS Close:
                        </div>
                        <div class="column">
                            <VeeInput
                                type="number" 
                                v-model="fuelInvEdit.endingVolumePOS"
                                name="endingVolumePOS"
                                :required="fuelInvEdit.endingVolumePOS == 0"
                                :value="fuelInvEdit.endingVolumePOS"
                                :editable="editable"
                                @focus="$event.target.select()"
                                />
                        </div>                    
                    </div>
                    <div class="columns is-mobile">
                        <div class="column">
                            POS Variance:
                        </div>
                        <div class="column">
                            {{ formatNumberWithCommas((fuelInvEdit.endingVolumePOS - (fuelInvEdit.beginningVolumePOS + fuelInvEdit.deliveredVolumePOS - fuelInvEdit.soldVolume)),3)}}
                        </div>                    
                    </div>
                    
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-success" 
                            type="button" 
                            @click="submitForm" 
                            :disabled='isSaveDisabled'>Save</button>
                    <button class="button" type="button" @click="closeDialog">Cancel</button>
                    <span class='is-danger'></span>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>

import VeeInput from "../../../components/app/VeeInput.vue"

export default {
    props: {
        isShowEditDialog: Boolean,
        fuelInvEdit: {},
    },

    components: {
        VeeInput,
    },

    directives: {
        focus: {
            // directive definition
            inserted: function (el) {
                el.focus()
            }
        }
    },

    data() {
        return {
            isPrinting: false,
            showNumberControls: false,
            isMobileNativeDatePicker: false,
            maxClearDate: new Date(),
            isSaveDisabled: false,
            editable: true,
        }
    },

    methods: {
        submitForm() {
            this.$emit('save-record')
        },
        closeDialog() {

            this.$buefy.dialog.confirm({
                title: 'Leaving without saving!',
                message: 'Are you sure you want to discard all changes and leave?',
                confirmText: 'Discard Changes',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.$emit('hide-dialog')
                }
            })            

        },
    }
}
</script>
    
